﻿/**
 *  @ngdoc controller
 *  @name Autocomplete Directive
 *  @description Auto-complete Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('autocomplete', function ($timeout) {
        return {
            controller: 'autocompleteController',
            restrict: 'E',
            replace: true,
            link: function link(scope, element, attrs) {
                var hasFocus = attrs.autoFocus !== undefined;
                if (hasFocus) {
                    $(`#${scope.id} input`).focus()
                }
            },
            scope: {
                choices: '=',
                enteredtext: '=',
                minlength: '@',
                selected: '=',
                type: '=',
                placeholder: '@',
                isdisabled: "=",
                limit: "=",
                required: "=",
                id: "@",
                name: "@",
                fetchall: '@',
                param: "=",
                classname:"@"
            },
            templateUrl: 'app/directive/autocomplete/autocomplete.html'
        }
    }).
        controller('autocompleteController', function ($scope, eyeResource) {
            if (!$scope.id) {
                console.error(`please give id attribute for your autocomplete ${$scope.type} input`);
                return;
            }
            
            $scope.minlength = $scope.minlength == null || isUndefined($scope.minlength) ? 1 : $scope.minlength;

            $scope.fetchActive = $scope.fetchall ? null : "true";

            $scope.filteredChoices = [];
            $scope.isVisible = {
                suggestions: false
            };

            $scope.suggetionmsg = '';

            $scope.filterItems = function () {
                $scope.isVisible.suggestions = false;
                if ($scope.enteredtext) {
                    if ($scope.minlength <= $scope.enteredtext.length) {
                        querySearch($scope.enteredtext);
                    } else {
                        $scope.isVisible.suggestions = false;
                    }
                }
            };

            /*on select*/
            $scope.selectItem = function (index, e) {
                if ($scope.filteredChoices && $scope.filteredChoices.length >= index) {
                    var selected = $scope.filteredChoices[index - 1];
                    $scope.enteredtext = selected.label;
                    $scope.selected = selected;
                    if (e.type == "keydown") {
                        $scope.$apply(function () {
                            $scope.isVisible.suggestions = false;
                        });
                    } else {
                        $scope.isVisible.suggestions = false;
                    }

                }
            };

            var curruntRow = -1;

            function querySearch(query) {
                if (query && query.length >= $scope.minlength) {
                    var limit = 15;
                    if ($scope.limit) {
                        limit = $scope.limit;
                    }
                    curruntRow = -1;
                    $scope.suggetionmsg = 'loading..';
                    $scope.filteredChoices = [];
                    var param = { keyword: query, type: $scope.type, limit: limit, fetchActive: $scope.fetchActive };
                    var parameter = { ...param, ...$scope.param };
                    $scope.loader = true;
                    eyeResource.getAutoCompleteList(
                        { param: JSON.stringify(parameter) },
                        function (data) {
                            $scope.loader = false;
                            $scope.filteredChoices = [];
                            if (data.result && data.result.length > 0) {
                                $scope.choices = data.result;
                                var result = query ? $scope.choices : [];
                                $scope.filteredChoices = result;
                                $scope.isVisible.suggestions = result.length > 0 ? true : false;
                                $scope.suggetionmsg = '';
                                setTimeout(() => {
                                    var e = $.Event('keydown');
                                    e.which = 40; //
                                    $(`#${$scope.id}`).trigger(e);
                                }, 100);
                            } else {
                                $scope.suggetionmsg = 'NO RECORD';
                            }
                        },
                        function (err) {
                            $scope.suggetionmsg = 'NO RECORD';
                            $scope.loader = false;
                            $scope.suggetionmsg = '';
                        })
                }
            }

            document.addEventListener("click", function (e) {
                $scope.$apply(function () {
                    $scope.isVisible.suggestions = false;
                    $scope.suggetionmsg = '';
                });
            });

            $(`#${$scope.id}`).click(function (e) {
                //e.stopPropagation();
            })

            $(`#${$scope.id}`).keydown(function (e) {
                if ($scope.filteredChoices && $scope.filteredChoices.length > 0) {
                    if (e.which == 40) {
                        curruntRow += 1;
                        keyPress();
                    } else if (e.which == 38) {
                        curruntRow -= 1;
                        keyPress();
                    } else if (e.which == 13 || e.which == 9) {
                        $scope.selectItem(curruntRow + 1, e);
                    }
                } else {
                    $scope.selected = $scope.selected == null ? 0 : $scope.selected;
                }
            });

            function keyPress() {
                $(`.total_autocomplete a`).removeClass('active');
                if (curruntRow >= $scope.filteredChoices.length)
                    curruntRow = 0;
                if (curruntRow < 0)
                    curruntRow = $scope.filteredChoices.length - 1;
                $(`.total_autocomplete #li${curruntRow}`).addClass('active');
                $(`.total_autocomplete`).scrollTop(0);
                //$(`.total_autocomplete`).scrollTop($(`#${$scope.id} .active:first`).offset().top - $(`.total_autocomplete`).height());
                $(`.total_autocomplete`).scrollTop(30 * curruntRow)
            }
        });

}(window.angular));